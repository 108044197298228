window.chat = {id:"C06CD65D-5B28-470D-B542-EDC4C1CE46B2",position:"bottom right", margins: "16 16"};
var chatScript = document.createElement("script");
chatScript.setAttribute("src", "https://chatsales-app.leadlovers.com/scripts/widget.js");
document.addEventListener("DOMContentLoaded", function () {
    document.body.appendChild(chatScript);
});


window.llPopupId="AD12961E74B14CC68A7CCA354F49A61E";
var llP = document.createElement("script");
llP.setAttribute("src", "https://pagina.group/scripts/capture/capture-popup.js");
document.addEventListener("DOMContentLoaded", function() {
  document.body.appendChild(llP)
});
